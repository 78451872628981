import React, { useState, useRef, useCallback, useEffect } from "react"
import Img from "gatsby-image"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"
import { useAnimationFrame } from "../hooks/raf"

import iconNext from "../images/arrow_next.svg"
import iconPrev from "../images/arrow_prev.svg"

import styled from "styled-components"

const SliderWrapper = styled.div`
  position: relative;
  overflow: hidden;

  & .container {
    overflow: hidden;
    display: grid;
    height: 100vh;

    @media only screen and (max-width: 760px) {
      height: 70vh;
    }

    & .gatsby-image-wrapper {
      height: 100vh;
      max-height: 100vh;

      @media only screen and (max-width: 760px) {
        height: 70vh;
      }
    }
  }
`

const SliderControls = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
 

  .next,
  .prev {
    width: 50%;
    outline: none;
    border: none;
  }
`

const Corusor = styled.div`
  z-index: 99;
  position: absolute;
  width: 122px;
  height: 65px;
  transform: translate(-50%, -50%);
  transform-origin: 100% 100%;
  pointer-events: none;
  filter: ${props => props.inverted && `invert(1)`};

  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const Slider = ({ images, autoplay }) => {
  const [[page, direction], setPage] = useState([0, 0])
  const [count, setCount] = React.useState(1)

  const cursorRef = useRef()
  const sliderRef = useRef()

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  const handleKeyPress = (e, direction) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      paginate(direction)
    }
  }

  let delta = 0

  function update() {
    delta += 1
    if (delta % 400 === 0) {
      setCount(prevcount => prevcount + 1)
      delta = 0
    }
  }

  useEffect(() => {
    paginate(1)
  }, [count])

  useAnimationFrame(() => {
    if (autoplay) {
      update()
    }
    return
  })

  const changeCursorHandler = useCallback(e => {
    const xOffset = document.body.clientWidth - sliderRef.current.clientWidth
    const yOffset = document.body.clientHeight - sliderRef.current.clientHeight

    cursorRef.current.style.left = e.pageX - xOffset + "px"
    cursorRef.current.style.top = e.pageY - yOffset + "px"

    if (e.target.className === "next") {
      cursorRef.current.style.background = `url(${iconNext})`
    } else if (e.target.className === "prev") {
      cursorRef.current.style.background = `url(${iconPrev})`
    } else {
      cursorRef.current.style.background = ""
    }
  }, [])

  const imageIndex = wrap(0, images.length, page)

  return (
    <>
      <SliderWrapper ref={sliderRef} onMouseMove={e => changeCursorHandler(e)}>
        <AnimatePresence custom={direction} initial={false}>
          <div className="container">
            <motion.div
              key={page}
              initial={{ opacity: 0, x: direction > 0 ? 200 : -200 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0 }}
              drag={autoplay ? null : "x"}
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic="1"
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  if (!autoplay) {
                    paginate(1)
                  }
                } else if (swipe > swipeConfidenceThreshold) {
                  if (!autoplay) {
                    paginate(-1)
                  }
                }
              }}
            >
              <Img
                fluid={images[imageIndex].url.childImageSharp.fluid}
                fadeIn
              />
              <SliderControls>
                <div
                  role="button"
                  aria-label="Next Slide"
                  tabIndex="0"
                  className="prev"
                  onKeyDown={e => handleKeyPress(e, -1)}
                  onClick={autoplay ? null : () => paginate(-1)}
                ></div>
                <div
                  role="button"
                  aria-label="Prev Slide"
                  tabIndex="0"
                  className="next"
                  onKeyDown={e => handleKeyPress(e, 1)}
                  onClick={autoplay ? null : () => paginate(1)}
                ></div>
              </SliderControls>
            </motion.div>
          </div>
        </AnimatePresence>
        {/* </div> */}

        <Corusor
          ref={cursorRef}
          inverted={images[imageIndex].inverted}
        ></Corusor>
      </SliderWrapper>
    </>
  )
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

export default Slider
